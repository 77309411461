<template>
	<div>
		<v-row
			v-intro-autostart="autoStartTourRules"
			v-intro-autostart.config="autostartRulesConfig"
			v-intro-autostart:on.complete="onFinishTourRules"
			v-intro-autostart:on.exit="onFinishTourRules"
			class="mt-1"
		>
			<v-col cols="12" md="3" class="blockRules hide-scroll-bar">
				<SearchAndFilter
					@familySelected="familySelected"
					@statusSelected="statusSelected"
					@searchRules="searchRulesFind"
					id="search"
					v-intro="'<h2>Recherche les règles que tu veux revoir</h2>Utilise les filtres ou la barre de recherche'"
					v-intro-tooltip-class="'custom-tooltip-introjs'"
				/>
				<CardsObjectivesRules
					class="CardsObjectivesRules-mobile"
					:totalRuleOfFormation="totalRulesOfFormation"
					:nbRulesValidated="nbRulesValidated"
					:nbRulesMemorized="nbRulesMemorized"
					:nbreRulesView="nbreRulesView"
					id="objective-rule"
					v-intro-tooltip-class="'custom-tooltip-introjs'"
					v-intro="
						'<h2>80 règles à maîtriser pour être certifié.e</h2>Maîtrise les 80 règles de la formation pour obtenir ta certification OrthographIQ.'
					"
				/>
				<div
					v-for="(rule, index) in rulesDisplay"
					class="mb-2 cardRules"
					:key="index"
					:id="index == 0 ? 'rule' : false"
					@click="displayRulesInfos(rule)"
					v-scroll-to="{
						el: '#rule-' + rule.id,
						duration: 1000,
					}"
					v-intro="
						'<h2>Clique sur une règle pour la revoir</h2>Retrouve la description de la règle ainsi que les sessions associées.'
					"
					v-intro-tooltip-class="'custom-tooltip-introjs'"
					v-intro-if="index == 0"
				>
					<RulesCards :rule="rule" :ruleSelected="ruleSelected" />
				</div>
			</v-col>
			<v-col id="rulesInfo hide-scroll-bar" cols="12" md="9">
				<CardsObjectivesRules
					class="CardsObjectivesRules-desktop"
					:totalRuleOfFormation="totalRulesOfFormation"
					:nbRulesValidated="nbRulesValidated"
					:nbRulesMemorized="nbRulesMemorized"
					:nbreRulesView="nbreRulesView"
					id="objective-rule"
					v-intro-tooltip-class="'custom-tooltip-introjs'"
					v-intro="
						'<h2>80 règles à maîtriser pour être certifié.e</h2>Maîtrise les 80 règles de la formation pour obtenir ta certification OrthographIQ.'
					"
				/>
				<div v-if="displayRules">
					<RulesInfos
						:infosRules="infosRules"
						:idFormation="$route.params.idFormation"
						@displayRuleFromSession="displayRuleFromSession"
					/>
				</div>
				<div class="d-flex justify-center align-center flex-column mt-10" style="height: 60%" v-else>
					<img src="@/assets/images/direction.png" alt="arrow direction" id="imgDirection" style="width: 20rem" />
					<p class="font-weight-bold rulesInfo-instruction-desktop">← Clique sur une règle pour revoir l'astuce</p>
				</div>
			</v-col>
		</v-row>
		<ModalForRestrictedUser :testTrialData="formationInfos.test_trial" modalName="reglesPage" />
		<Loading :show="loading_show" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import RulesCards from './RulesCards';
import RulesInfos from './RulesInfos';
import CardsObjectivesRules from './CardsObjectivesRules';
import SearchAndFilter from './SearchAndFilter';
import Loading from 'vue-full-loading';
import ModalForRestrictedUser from '../../other/ModalForRestrictedUser/ModalForRestrictedUser';

export default {
	name: 'Index',
	computed: {
		...mapGetters('profile', {
			formationInfos: 'formationInfos',
			rulesUser: 'rulesUser',
		}),
		nbreRulesView() {
			return this.rulesUser.length;
		},
		totalRulesOfFormation() {
			const uniqueParoursId = [];
			this.rulesUser.forEach((rule) => {
				rule.infosExo.forEach((item) => {
					if (!uniqueParoursId.includes(item.exercices_id.cat_id.parcours_id))
						uniqueParoursId.push(item.exercices_id.cat_id.parcours_id);
				});
			});
			return uniqueParoursId.length * 80; // Formation has 1 parcours: 80 rules, 2 parcours: 160, 3 parcours: 240
		},
	},
	data() {
		return {
			infosRules: [],
			displayRules: false,
			nbRulesValidated: 0,
			nbRulesMemorized: 0,
			rulesDisplay: null,
			ruleSelected: null,
			statusSelect: 'All',
			familySelect: 'All',
			loading_show: false,
			autoStartTourRules: false,
			autostartRulesConfig: {
				nextLabel: 'Suivant',
				prevLabel: 'Retour',
				skipLabel: 'Sortir',
				doneLabel: 'Terminé',
				showStepNumbers: false,
			},
		};
	},
	components: {
		RulesCards,
		RulesInfos,
		CardsObjectivesRules,
		SearchAndFilter,
		Loading,
		ModalForRestrictedUser,
	},
	created() {
		if (localStorage.getItem('tour') == 'rules') this.autoStartTourRules = false;
	},
	async mounted() {
		try {
			await this.$store.dispatch('profile/getRules', { idFormation: this.$route.params.idFormation });
		} catch (error) {
			console.log(error);
		}
		if (this.$route.query.rules != undefined) {
			this.displayRules = true;
			this.infosRules = this.rulesUser.find((element) => element.id == this.$route.query.rules);
			this.ruleSelected = this.infosRules.name;
		}
		this.rulesDisplay = this.rulesUser;
		this.countValidatedRules(this.rulesUser);
		this.countMemorizedRules(this.rulesUser);
		if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('dashboard-rules');
	},
	methods: {
		displayRulesInfos(ruleToDisplay) {
			this.infosRules = ruleToDisplay;
			this.displayRules = true;
			this.ruleSelected = ruleToDisplay.name;
			if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('dashboard-rules-ruleSelection');
		},
		displayRuleFromSession(value) {
			this.loading_show = true;
			setTimeout(() => {
				this.loading_show = false;
				document.getElementById('rulesInfo').scrollTo(0, 0);
				this.infosRules = this.rulesUser.find((element) => element.name == value);
				this.displayRules = true;
				this.ruleSelected = value;
			}, 1000);
		},
		countValidatedRules(rulesUser) {
			rulesUser.map((rule) => {
				if (rule.score >= 100 && rule.score < 150) this.nbRulesValidated++;
			});
		},
		countMemorizedRules(rulesUser) {
			rulesUser.map((rule) => {
				if (rule.score == 150) this.nbRulesMemorized++;
			});
		},
		familySelected(value) {
			if (value != 'Toutes les familles') this.familySelect = value;
			else this.familySelect = 'All';

			this.rulesToDisplay();
		},
		statusSelected(value) {
			if (value != 'Tous les statuts') this.statusSelect = value;
			else this.statusSelect = 'All';

			this.rulesToDisplay();
		},
		searchRulesFind(value) {
			this.rulesDisplay = this.rulesUser.filter((element) => element.name.toLowerCase().includes(value.toLowerCase()));
		},
		rulesToDisplay() {
			if (this.statusSelect != 'All' && this.familySelect != 'All') {
				this.rulesDisplay = this.rulesUser.filter((element) => {
					if (this.statusSelect == 'En cours') {
						return element.score < 100 && element.familyTagName == this.familySelect;
					} else if (this.statusSelect == 'Maîtrisées') {
						return element.score >= 100 && element.score < 150 && element.familyTagName == this.familySelect;
					} else if (this.statusSelect == 'Mémorisées') {
						return element.score == 150 && element.familyTagName == this.familySelect;
					}
				});
			} else if (this.statusSelect == 'All' && this.familySelect != 'All') {
				this.rulesDisplay = this.rulesUser.filter((element) => {
					return element.familyTagName == this.familySelect;
				});
			} else if (this.statusSelect != 'All' && this.familySelect == 'All') {
				this.rulesDisplay = this.rulesUser.filter((element) => {
					if (this.statusSelect == 'En cours') {
						return element.score < 100;
					} else if (this.statusSelect == 'Maîtrisées') {
						return element.score >= 100 && element.score < 150;
					} else if (this.statusSelect == 'Mémorisées') {
						return element.score == 150;
					}
				});
			} else if (this.statusSelect == 'All' && this.familySelect == 'All') {
				this.rulesDisplay = this.rulesUser;
			}
		},
		onFinishTourRules() {
			localStorage.setItem('tour', 'rules');
		},
	},
};
</script>

<style lang="scss">
.tooltip-Css {
	font-family: 'Lato';
	h2 {
		color: #2dc7ae;
		font-weight: bold;
		font-size: 20px;
	}
}
.blockRules,
#rulesInfo {
	height: 90vh;
	overflow: scroll;
}

.CardsObjectivesRules-mobile {
	display: none;
}

@media only screen and (max-width: 600px) {
	#imgDirection {
		display: none;
	}
	.blockRules {
		height: 75vh;
	}
	#rulesInfo {
		height: fit-content;
		overflow: auto;
	}
	.CardsObjectivesRules-desktop {
		display: none;
	}
	.rulesInfo-instruction-desktop {
		display: none;
	}
	.CardsObjectivesRules-mobile {
		display: flex;
		margin-bottom: 20px;
	}
}
</style>
